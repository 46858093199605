/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {


        //OnScroll
        $(window).on('scroll', function () {
          if ($(this).scrollTop() > 5) {
            $('body').addClass("header-active");
          } else {
            $('body').removeClass("header-active");
          }
        });//end on Scroll


        //Mobile nav menu click
        $(document).on('click', 'div.hamburger', function () {
          $(this).toggleClass('is-active');
          $('div.navbar-collapse').toggleClass('collapse');
          $('header.header').toggleClass('active');
        });



        //Close the mobile menu on body click
        $(document).on('click', 'body > main', function () {
          if ($('div.hamburger').hasClass('is-active')) {
            $('div.hamburger').trigger('click');
          }
        });


        //Page scroll
        $("a.page-scroll").bind("click", function (e) {
          var offset = 75,
              t = $(this);
          $("html, body").stop().animate({
            scrollTop: (parseInt($(t.attr("href")).offset().top) - offset)
          }, 1500, "easeInOutExpo");
          e.preventDefault();
        });



        //Append a span to the cf7 checkbox elements for styling the checkbox
        if ($('span.wpcf7-checkbox').length) {
          $('<span class="checkbox-wrap"/>').insertAfter('span.wpcf7-checkbox > span > input[type="checkbox"]');
        }


        //CF7 Google Analytics form sent tracking
        document.addEventListener('wpcf7mailsent', function (event) {

          //Wrap the inner text in a div for display:
          setTimeout(function () {
            var text = $('.wpcf7-response-output').text();
            $('.wpcf7-response-output').html('<div class="cf7-inner"><p>' + text + '</p></div>');
          }, 350);

          fbq('track', 'Contact');

          if ("ga" in window) {
            var tracker = ga.getAll()[0];
            if (tracker) {
              tracker.send('event', 'Contact Form', 'submit');
            }
          }
        }, false);



        //Image carousels
        $('.img-carousel').owlCarousel({
          items: 1,
          dots: true,
          nav: false
        });//end img carousel



        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
